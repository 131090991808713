'use strict';

angular.module('mainApp').directive('menuSection', function (getTemplate, menuDataMap, designData) {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: 'menuSection.html',
        scope: true,
        link: function(scope, element, attrs) {
            scope.designData = designData
            scope.$watch('designData.isReversed.value()', function(isReversed){
                var clothingType = getTemplate().clothingType;
                if(isReversed === 'on'){
                    clothingType += 'Inside'
                }

                scope.sectionName = attrs.id;
                scope.sectionData = menuDataMap[clothingType][attrs.id] || menuDataMap[attrs.id];
                scope.sectionTemplate = scope.sectionData.template;
            })
        }
    };
});

angular.module('mainApp').animation('.menu__section__content_expand', ['$window', '$timeout', function ($window, $timeout) { // TODO ngMin fails to wrap dependencies for .animation()

    function setFullHeight(element) {
        var child = element.children().eq(0),
            height = 'auto';

        if (child) {
            height = child.prop('offsetHeight') + 'px';
        }

        element.css('height', height);
    }

    function removeHeight(element) {
        element.css('height', '');
    }

    var TRANSITION_DURATION_PROPERTY;

    if (($window.ontransitionend === undefined) && ($window.onwebkittransitionend !== undefined)) {
        TRANSITION_DURATION_PROPERTY = 'WebkitTransitionDuration';
    } else {
        TRANSITION_DURATION_PROPERTY = 'transitionDuration';
    }

    function parseTime(str) {
        var maxValue = 0,
            values = angular.isString(str) ? str.split(/\s*,\s*/) : [];

        angular.forEach(values, function(value) {
            value = value.indexOf('ms') !== -1 ? parseFloat(value) : parseFloat(value) * 1000;
            maxValue = Math.max(parseFloat(value) || 0, maxValue);
        });

        return maxValue;
    }

    return {
        addClass: function (element, className, done) {
            var elementStyles = $window.getComputedStyle(element[0]) || {};

            setFullHeight(element);

            $timeout(done, parseTime(elementStyles[TRANSITION_DURATION_PROPERTY]));

            return function () {
                removeHeight(element);
            };
        },
        beforeRemoveClass: function (element, className, done) {
            setFullHeight(element);
            done();
        },
        removeClass: function (element, className, done) {
            removeHeight(element);
            done();
        }
    };
}]);

angular.module('mainApp').animation('.menu__section__content_open', ['$window', '$timeout', function ($window, $timeout) { // TODO ngMin fails to wrap dependencies for .animation()

    function setFullHeight(element) {
        var child = element.children().eq(0),
            height = 'auto';

        if (child) {
            height = child.prop('offsetHeight') + 'px';
        }

        element.css('height', height);
    }

    function removeHeight(element) {
        element.css('height', '');
    }

    return {
        beforeRemoveClass: function (element, className, done) {
            setFullHeight(element);
            done();
        },
        removeClass: function (element, className, done) {
            removeHeight(element);
            done();
        }
    };
}]);
