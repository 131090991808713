'use strict'

angular
    .module('mainApp')
    .factory('designData', function(
        TextProperty,
        LimitedProperty,
        vendorConfig,
        configService
    ) {
        // NB! configService is needed here to guarantee that vendorConfig is already mutated.
        // Don't want to refactor now.

        var _ = require('lodash')
        var sheets = require('data').sheets
        var whitelists = require('data').whitelists
        var config = require('data').config

        var BASEBALL = config.sportId === 'baseball'
        var SOFTBALL = config.sportId === 'softball'
        var FOOTBALL = config.sportId === 'football'

        var JERSEY_AREA_COUNT =
            (BASEBALL && 7) || (SOFTBALL && 7) || (FOOTBALL && 10)
        var PANT_AREA_COUNT =
            (BASEBALL && 5) || (SOFTBALL && 5) || (FOOTBALL && 5)

        var designData = {
            clothingType: new LimitedProperty(
                whitelists.clothingTypes.all,
                sheets.clothingTypes
            ),
            designTemplate: new LimitedProperty(
                whitelists.garments[vendorConfig.productSubset],
                sheets.garments
            ),
            filter: getFilters(),

            isReversed:
                FOOTBALL &&
                new LimitedProperty(whitelists.toggles.all, sheets.toggles),
            jersey: getJersey(),
            jerseyInside:
                FOOTBALL && _.omit(getJersey(), ['model', 'colorAreas']),
            pant: getPant(),
        }

        function getFilters() {
            return {
                clothingType: new LimitedProperty(
                    whitelists.clothingTypes.all,
                    sheets.clothingTypes,
                    true
                ),
                clothingQuality:
                    FOOTBALL &&
                    new LimitedProperty(
                        whitelists.clothingQualities.all,
                        sheets.clothingQualities,
                        true
                    ),
                clothingCollection: new LimitedProperty(
                    whitelists.clothingCollections.all,
                    sheets.clothingCollections,
                    true
                ),
                clothingCutType: new LimitedProperty(
                    whitelists.cutTypes.all,
                    sheets.cutTypes
                ),
                clothingFitType:
                    FOOTBALL &&
                    new LimitedProperty(
                        whitelists.fitTypes.all,
                        sheets.fitTypes
                    ),
            }
        }

        function getJersey() {
            return {
                model: new LimitedProperty(
                    whitelists.garments.all,
                    sheets.garments
                ), // TODO should be removed
                colorAreas: _.transform(
                    _.range(JERSEY_AREA_COUNT),
                    function(result, index) {
                        result['area' + (index + 1)] = getColorAreaFill([])
                    },
                    {}
                ),
                options: {
                    OP1:
                        (BASEBALL || SOFTBALL) &&
                        get3ColorOption(
                            sheets.opPatterns,
                            whitelists.opPatterns.jersey,
                            whitelists.colors.sublimated
                        ),
                    OP2:
                        (BASEBALL || SOFTBALL) &&
                        get3ColorOption(
                            sheets.opPatterns,
                            whitelists.opPatterns.jersey,
                            whitelists.colors.sublimated
                        ),
                    OP2_5:
                        BASEBALL &&
                        get3ColorOption(
                            sheets.opPatterns,
                            whitelists.opPatterns.jersey,
                            whitelists.colors.sublimated
                        ),
                    OP3:
                        (BASEBALL || SOFTBALL) &&
                        get3ColorOption(
                            sheets.opPatterns,
                            whitelists.opPatterns.jersey,
                            whitelists.colors.sublimated
                        ),

                    CLSU:
                        FOOTBALL &&
                        get3ColorOption(
                            sheets.ribKnitPatterns,
                            whitelists.ribKnitPatterns.CLSU,
                            whitelists.colors.ribKnit
                        ),

                    ribKnitCollar:
                        (BASEBALL || SOFTBALL) &&
                        get3ColorOption(
                            sheets.ribKnitPatterns,
                            whitelists.ribKnitPatterns.collar,
                            whitelists.colors.ribKnit
                        ),
                    ribKnitCuff:
                        (BASEBALL || SOFTBALL) &&
                        get3ColorOption(
                            sheets.ribKnitPatterns,
                            whitelists.ribKnitPatterns.cuff,
                            whitelists.colors.ribKnit
                        ),

                    sublimatedCollar:
                        (BASEBALL || SOFTBALL) &&
                        get3ColorOption(
                            sheets.ribKnitPatterns,
                            whitelists.ribKnitPatterns.collar,
                            BASEBALL
                                ? whitelists.colors.SBPT
                                : whitelists.colors.sublimated
                        ),
                    sublimatedCuff:
                        (BASEBALL || SOFTBALL) &&
                        get3ColorOption(
                            sheets.ribKnitPatterns,
                            whitelists.ribKnitPatterns.cuff,
                            BASEBALL
                                ? whitelists.colors.SBPT
                                : whitelists.colors.sublimated
                        ),
                    sleeveStripes:
                        FOOTBALL &&
                        get2ColorOption(
                            sheets.sleeveStripes,
                            whitelists.sleeveStripes.all,
                            whitelists.colors.sublimated
                        ),
                    buttonColor:
                        (BASEBALL || SOFTBALL) &&
                        new LimitedProperty(
                            whitelists.colors.button,
                            sheets.colors
                        ),
                    logoColor: new LimitedProperty([], sheets.colors),
                },
                decoration: {
                    type: new LimitedProperty([], sheets.decoSets),
                    teamName: getText(getTailing()),
                    playerName: getText(),
                    number: {
                        front: getNumber(),
                        back: getNumber(),
                        sleeve: getNumber(),
                    },
                    logo: {
                        stock: getStockLogo(),
                        custom: getCustomLogo(),
                    },
                    watermarkText: getWatermarkText(),
                    watermarkLogo: {
                        stock: getStockWatermarkLogo(),
                        custom: getCustomLogo(),
                    },
                },
            }
        }

        function getPant() {
            return {
                model: new LimitedProperty(
                    whitelists.garments.all,
                    sheets.garments
                ),
                colorAreas: _.transform(
                    _.range(PANT_AREA_COUNT),
                    function(result, index) {
                        result['area' + (index + 1)] = getColorAreaFill([])
                    },
                    {}
                ),
                options: {
                    OP4:
                        (BASEBALL || SOFTBALL) &&
                        get3ColorOption(
                            sheets.opPatterns,
                            whitelists.opPatterns.op4,
                            whitelists.colors.braid
                        ),
                    OP4_2:
                        SOFTBALL &&
                        get3ColorOption(
                            sheets.opPatterns,
                            whitelists.opPatterns.op4,
                            whitelists.colors.braid
                        ),
                    OP4B:
                        BASEBALL &&
                        get3ColorOption(
                            sheets.opPatterns,
                            whitelists.opPatterns.op4,
                            whitelists.colors.braid
                        ),
                    OP4C:
                        BASEBALL &&
                        get3ColorOption(
                            sheets.opPatterns,
                            whitelists.opPatterns.op4,
                            whitelists.colors.braid
                        ),
                    OP15:
                        (BASEBALL || SOFTBALL) &&
                        get3ColorOption(
                            sheets.opPatterns,
                            whitelists.opPatterns.op15,
                            whitelists.colors.braid
                        ),

                    RIBWST:
                        (BASEBALL || SOFTBALL) &&
                        get3ColorOption(
                            sheets.ribKnitPatterns,
                            whitelists.ribKnitPatterns.RIBWST,
                            whitelists.colors.ribKnit
                        ),

                    beltColor:
                        FOOTBALL && new LimitedProperty([], sheets.colors),
                    logoColor: new LimitedProperty(
                        whitelists.colors.embroidery,
                        sheets.colors
                    ),
                },
                decoration: {
                    type: new LimitedProperty([], sheets.decoSets),

                    teamName: (SOFTBALL || FOOTBALL) && getText(),
                    logo: (SOFTBALL || FOOTBALL) && {
                        stock: getStockLogo(),
                        custom: getCustomLogo(),
                    },
                    upperLogo: FOOTBALL && {
                        stock: getStockLogo(),
                        custom: getCustomLogo(),
                    },
                },
            }
        }

        function getColorAreaFill(colorWhitelist) {
            return {
                fabric: SOFTBALL && new LimitedProperty([], sheets.fabrics),
                pattern: new LimitedProperty([], sheets.designPatterns),
                color_1: new LimitedProperty(colorWhitelist, sheets.colors),
                color_2: new LimitedProperty(colorWhitelist, sheets.colors),
                color_3: new LimitedProperty(colorWhitelist, sheets.colors),
                color_4: new LimitedProperty(colorWhitelist, sheets.colors),
            }
        }

        function getPatternFill(colorWhitelist) {
            return {
                pattern: new LimitedProperty([], sheets.designPatterns),
                color_1: new LimitedProperty(colorWhitelist, sheets.colors),
                color_2: new LimitedProperty(colorWhitelist, sheets.colors),
                color_3: new LimitedProperty(colorWhitelist, sheets.colors),
                color_4: new LimitedProperty(colorWhitelist, sheets.colors),
            }
        }

        function get3ColorOption(typeItemMap, typeWhitelist, colorWhitelist) {
            return {
                type: new LimitedProperty(typeWhitelist, typeItemMap),
                color_1: new LimitedProperty(colorWhitelist, sheets.colors),
                color_2: new LimitedProperty(colorWhitelist, sheets.colors),
                color_3: new LimitedProperty(colorWhitelist, sheets.colors),
            }
        }

        function get2ColorOption(typeItemMap, typeWhitelist, colorWhitelist) {
            return {
                type: new LimitedProperty(typeWhitelist, typeItemMap),
                color_1: new LimitedProperty(colorWhitelist, sheets.colors),
                color_2: new LimitedProperty(colorWhitelist, sheets.colors),
            }
        }

        function getText(tailing) {
            return {
                text: new TextProperty(),
                font: new LimitedProperty([], sheets.fonts),
                layout: new LimitedProperty(
                    whitelists.layouts.all,
                    sheets.layouts
                ),
                size: new LimitedProperty(
                    whitelists.decoSizes.all,
                    sheets.decoSizes
                ),
                placement: new LimitedProperty(
                    whitelists.decoPlacements.all,
                    sheets.decoPlacements
                ),
                fill: getPatternFill([]),
                outline_1: new LimitedProperty([], sheets.colors, true),
                outline_2: new LimitedProperty([], sheets.colors, true),
                tailing: tailing,
            }
        }

        function getWatermarkText() {
            return {
                text: new TextProperty(),
                font: new LimitedProperty([], sheets.fonts),
                layout: new LimitedProperty(
                    whitelists.layouts.all,
                    sheets.layouts
                ),
                size: new LimitedProperty(
                    whitelists.decoSizes.all,
                    sheets.decoSizes
                ),
                placement: new LimitedProperty(
                    whitelists.decoPlacements.all,
                    sheets.decoPlacements
                ),
            }
        }

        function getTailing() {
            return {
                enabled: new LimitedProperty(
                    whitelists.toggles.all,
                    sheets.toggles
                ),
                text: new TextProperty(),
                font: new LimitedProperty(whitelists.fonts.all, sheets.fonts),
                size: new LimitedProperty(
                    whitelists.decoSizes.all,
                    sheets.decoSizes
                ),
                fill: new LimitedProperty([], sheets.colors),
                outline: new LimitedProperty([], sheets.colors, true),
            }
        }

        function getNumber() {
            return {
                text: new TextProperty(),
                font: new LimitedProperty([], sheets.fonts),
                fill: getPatternFill([]),
                outline_1: new LimitedProperty([], sheets.colors, true),
                outline_2: new LimitedProperty([], sheets.colors, true),
                size: new LimitedProperty(
                    whitelists.decoSizes.all,
                    sheets.decoSizes
                ),
                placement: new LimitedProperty(
                    whitelists.decoPlacements.all,
                    sheets.decoPlacements
                ),
            }
        }

        function getStockLogo() {
            return {
                design: new LimitedProperty(whitelists.logos.all, sheets.logos),
                size: new LimitedProperty(
                    whitelists.decoSizes.all,
                    sheets.decoSizes
                ),
                placement: new LimitedProperty(
                    whitelists.decoPlacements.all,
                    sheets.decoPlacements
                ),
                fill: {
                    color_1: new LimitedProperty([], sheets.colors),
                    color_2: new LimitedProperty([], sheets.colors),
                    color_3: new LimitedProperty([], sheets.colors),
                },
            }
        }

        function getStockWatermarkLogo() {
            return {
                design: new LimitedProperty(whitelists.logos.all, sheets.logos),
                size: new LimitedProperty(
                    whitelists.decoSizes.all,
                    sheets.decoSizes
                ),
                placement: new LimitedProperty(
                    whitelists.decoPlacements.all,
                    sheets.decoPlacements
                ),
            }
        }

        function getCustomLogo() {
            return {
                customFile: new TextProperty(),
                size: new LimitedProperty(
                    whitelists.decoSizes.all,
                    sheets.decoSizes
                ),
                placement: new LimitedProperty(
                    whitelists.decoPlacements.all,
                    sheets.decoPlacements
                ),
            }
        }

        function addIsDisabled(value) {
            if (!value) {
                return value
            }
            if (_.isPlainObject(value)) {
                value = _.mapValues(value, addIsDisabled)
                value = _.pick(value, _.identity)
                Object.defineProperty(value, 'label', {
                    value: undefined,
                    writable: true,
                    enumerable: false,
                    configurable: false,
                })
                Object.defineProperty(value, '_isDisabled', {
                    value: {
                        global: false,
                    },
                    writable: false,
                    enumerable: false,
                    configurable: false,
                })
                Object.defineProperty(value, 'isDisabled', {
                    value: LimitedProperty.prototype.isDisabled,
                    writable: false,
                    enumerable: false,
                    configurable: false,
                })
                return value
            }
            if (_.isFunction(value.value)) {
                return value
            }
        }
        designData = _.cloneDeep(designData, addIsDisabled)

        window.designData = designData
        window.summary = function() {
            console.log(
                JSON.stringify(
                    require('data/model').getSummary(designData),
                    null,
                    4
                )
            )
        }

        return designData
    })
