'use strict';

angular.module('mainApp').factory('templateIdHandler', function (designData, updateDesignData, getDesignDataTemplate) {

    var designDataOutput = getDesignDataTemplate(getDefaultTemplateID());

    function getDefaultTemplateID() {
        return 'PJDIV47';
    }

    function getTemplateID() {
        return designData.designTemplate.value() || getDefaultTemplateID();
    }

    function applyTemplate(templateID) {
        if (!templateID) {
            return;
        }

        designData.designTemplate.value(templateID);
    }

    updateDesignData(designDataOutput);

    return {
        'getTemplateID': getTemplateID,
        'applyTemplate': applyTemplate
    }
});
