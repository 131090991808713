'use strict'

angular
    .module('mainApp')
    .factory('autoWatcher', function (_, $timeout, $rootScope, designData) {
        var scope = $rootScope.$new(true)
        scope.designData = designData

        var whitelists = require('data').whitelists

        var config = require('data').config
        var BASEBALL = config.sportId === 'baseball'
        var SOFTBALL = config.sportId === 'softball'
        var FOOTBALL = config.sportId === 'football'

        $timeout(function () {
            watchJersey(designData.jersey)
            designData.jerseyInside && watchJersey(designData.jerseyInside)
            watchPant(designData.pant)
        })

        function watchJersey(productData) {
            // COLOR AREAS
            if (productData.colorAreas) {
                SOFTBALL && autoUpdateColorAreas(productData.colorAreas)
                _.each(productData.colorAreas, autoToggleAreaFillColors)
                _.each(
                    designData.jerseyInside.colorAreas,
                    autoToggleAreaFillColors
                )

                autoPreventWatermarkOnPatternFill(
                    productData.colorAreas.area2.pattern,
                    [
                        productData.decoration.watermarkText.placement,
                        productData.decoration.watermarkLogo.stock.placement,
                        productData.decoration.watermarkLogo.custom.placement,
                    ]
                )
            }

            // OPTIONS
            _.each(productData.options, autoToggleOptionColors)

            // DECORATIONS
            var allDecos = [
                productData.decoration.teamName,
                productData.decoration.playerName,
                productData.decoration.number.front,
                productData.decoration.number.back,
                productData.decoration.number.sleeve,
                productData.decoration.logo.stock,
                productData.decoration.logo.custom,
                productData.decoration.watermarkLogo.stock,
                productData.decoration.watermarkLogo.custom,
                productData.decoration.watermarkText,
            ]

            _.each(allDecos, function (deco) {
                autoUpdateDecoSizeLegalValues(deco)
            })
            autoPreventSamePlacementValue(
                _.flatten([
                    productData.decoration.teamName.placement,
                    productData.decoration.playerName.placement,
                    _.pluck(productData.decoration.number, 'placement'),
                    _.pluck(productData.decoration.logo, 'placement'),
                    _.pluck(productData.decoration.watermarkLogo, 'placement'),
                    productData.decoration.watermarkText.placement,
                ])
            )
            designData.jerseyInside &&
                autoPreventSamePlacementValue(
                    _.flatten([
                        designData.jerseyInside.decoration.teamName.placement,
                        designData.jerseyInside.decoration.playerName.placement,
                        _.pluck(
                            designData.jerseyInside.decoration.number,
                            'placement'
                        ),
                        _.pluck(
                            designData.jerseyInside.decoration.logo,
                            'placement'
                        ),
                        _.pluck(
                            designData.jerseyInside.decoration.watermarkLogo,
                            'placement'
                        ),
                        designData.jerseyInside.decoration.watermarkText
                            .placement,
                    ])
                )

            _.each(
                _.flatten([
                    productData.decoration.teamName,
                    productData.decoration.playerName,
                    _.values(productData.decoration.number),
                ]),
                autoFixLetering
            )
            autoPreventTailing(productData.decoration.teamName)

            autoSync(
                [
                    productData.decoration.number.front,
                    productData.decoration.number.back,
                    productData.decoration.number.sleeve,
                ],
                ['text']
            )

            autoUnset([
                productData.decoration.logo.stock.placement,
                productData.decoration.logo.custom.placement,
            ])

            autoUnset([
                productData.decoration.watermarkLogo.stock.placement,
                productData.decoration.watermarkLogo.custom.placement,
            ])
            FOOTBALL &&
                autouseDecoPlacement([
                    productData.decoration.number.front,
                    productData.decoration.logo.stock,
                    productData.decoration.logo.custom,
                ])

            autoUpdateLogoColorAvailability(productData.decoration.logo.stock)
        }

        function watchPant(productData) {
            // PANT
            // COLOR AREAS
            SOFTBALL && autoUpdateColorAreas(productData.colorAreas)
            _.each(productData.colorAreas, autoToggleAreaFillColors)

            // OPTIONS
            _.each(productData.options, autoToggleOptionColors)

            // BASEBALL & SOFTBALL OPTIONS
            if (!FOOTBALL) {
                autoSync(
                    [
                        productData.options.OP4,
                        productData.options.OP4_2,
                        productData.options.OP4B,
                        productData.options.OP4C,
                    ],
                    ['color_1', 'color_2', 'color_3']
                )

                autoUnset([
                    productData.options.OP4 && productData.options.OP4.type,
                    productData.options.OP4_2 && productData.options.OP4_2.type,
                    productData.options.OP4B && productData.options.OP4B.type,
                    productData.options.OP4C && productData.options.OP4C.type,
                ])

                autoUnset([
                    productData.options.OP15.type,
                    productData.options.RIBWST.type,
                ])
            }

            var allDecos = [
                productData.decoration.teamName,
                productData.decoration.logo.stock,
                productData.decoration.logo.custom,
                productData.decoration.upperLogo.stock,
                productData.decoration.upperLogo.custom,
            ]
            _.each(allDecos, function (deco) {
                autoUpdateDecoSizeLegalValues(deco)
            })

            // SOFTBALL DECORATIONS
            if (SOFTBALL) {
                autoPreventSamePlacementValue(
                    _.flatten([
                        productData.decoration.teamName.placement,
                        _.pluck(productData.decoration.logo, 'placement'),
                    ])
                )

                autoFixOutline2(productData.decoration.teamName)

                autoUnset([
                    productData.decoration.logo.stock.placement,
                    productData.decoration.logo.custom.placement,
                ])

                autoUpdateLogoColorAvailability(
                    productData.decoration.logo.stock
                )
            }

            // FOOTBALL DECORATIONS
            if (FOOTBALL) {
                autoPreventSamePlacementValue(
                    _.flatten([
                        productData.decoration.teamName.placement,
                        _.pluck(productData.decoration.number, 'placement'),
                        _.pluck(productData.decoration.logo, 'placement'),
                    ])
                )

                autoFixLetering(productData.decoration.teamName)

                autoUnset([
                    productData.decoration.logo.stock.placement,
                    productData.decoration.logo.custom.placement,
                ])
                autoUnset([
                    productData.decoration.upperLogo.stock.placement,
                    productData.decoration.upperLogo.custom.placement,
                ])

                autouseDecoPlacement([
                    productData.decoration.logo.stock,
                    productData.decoration.logo.custom,
                ])

                autoUpdateLogoColorAvailability(
                    productData.decoration.logo.stock
                )
                autoUpdateLogoColorAvailability(
                    productData.decoration.upperLogo.stock
                )
            }
        }

        function autoUpdateColorAreas(colorAreas) {
            _.each(colorAreas, function (colorAreaProps) {
                scope.$watch(
                    'designData.' + colorAreaProps.fabric.$path + '.value()',
                    function (fabric) {
                        _.each(colorAreaProps, function (prop, field) {
                            if (_.contains(['fabric', 'pattern'], field)) {
                                return
                            }
                            if (prop._exception) {
                                return
                            }
                            prop.legalValues(whitelists.colors[fabric])
                        })
                    }
                )
            })
        }

        function autoToggleAreaFillColors(areaFill) {
            autoToggleColors(areaFill.pattern, areaFill)
        }

        function autoToggleOptionColors(option) {
            if (option.type == null) {
                return
            }
            autoToggleColors(option.type, option)
        }

        function autoToggleColors(colorCountHolderProp, colorPropList) {
            var colorPropList = _.transform(colorPropList, function (
                result,
                prop,
                key
            ) {
                if (_.startsWith(key, 'color_')) {
                    var colorId = parseInt(key.replace('color_', ''))
                    result[colorId] = prop
                }
            })
            scope.$watch(
                'designData.' + colorCountHolderProp.$path + '.item()',
                function (colorCountHolder) {
                    _.each(colorPropList, function (prop, colorId) {
                        prop.isDisabled(
                            'pattern',
                            !colorCountHolder ||
                                parseInt(colorId) > colorCountHolder.colorCount
                        )
                    })
                }
            )
        }

        function autoPreventSamePlacementValue(placementList) {
            placementList = _.compact(placementList)
            _.each(placementList, function (placement) {
                var otherPlacementList = _.without(placementList, placement)
                scope.$watch(
                    'designData.' + placement.$path + '.value()',
                    function (value) {
                        if (!value || value === 'none') {
                            return
                        }

                        _.each(otherPlacementList, function (otherPlacement) {
                            if (
                                otherPlacement.value() === value ||
                                _.contains(
                                    placement.item().conflictsWith,
                                    otherPlacement.value()
                                )
                            ) {
                                otherPlacement.value('none')
                            }
                        })
                    }
                )
            })
        }

        function autoPreventWatermarkOnPatternFill(
            fillAreaPattern,
            decoPlacements
        ) {
            scope.$watch(
                'designData.' + fillAreaPattern.$path + '.value()',
                function (pattern) {
                    var isDisabled = pattern !== 'solidColor'
                    _.each(decoPlacements, function (decoPlacement) {
                        decoPlacement.isDisabled(
                            'body fill pattern',
                            isDisabled
                        )
                    })
                }
            )
        }

        function autoUpdateLogoColorAvailability(deco) {
            var designProp = deco.design
            var color1Prop = deco.fill.color_1
            var color2Prop = deco.fill.color_2
            var color3Prop = deco.fill.color_3

            scope.$watch(
                'designData.' + designProp.$path + '.value()',
                function (value) {
                    var design = designProp.item()
                    var colorCount =
                        value == null || value == 'none' ? 0 : design.colorCount
                    color1Prop.isDisabled('colorCount < 1', colorCount < 1)
                    color2Prop.isDisabled('colorCount < 2', colorCount < 2)
                    color3Prop.isDisabled('colorCount < 3', colorCount < 3)

                    if (deco.size) {
                        deco.size.$legalValuesFromDesign = design
                            ? design.sizeList
                            : undefined
                    }
                }
            )
        }

        function autoUpdateDecoSizeLegalValues(deco) {
            var size = deco.size
            var design = deco.design
            var placement = deco.placement

            function onChange() {
                var fromDesign = size.$legalValuesFromDesign
                if (fromDesign && fromDesign.length) {
                    size.legalValues(fromDesign)
                    return
                }

                if (!size.$legalValuesByPlacement) {
                    return
                }
                var fromPlacement =
                    size.$legalValuesByPlacement[placement.value()]
                size.legalValues(fromPlacement)
            }

            scope.$watch(
                'designData.' + size.$path + '.$legalValuesFromDesign',
                onChange
            )
            scope.$watch(
                'designData.' + size.$path + '.$legalValuesByPlacement',
                onChange
            )
            scope.$watch('designData.' + placement.$path + '.value()', onChange)
        }

        function autoFixLetering(letteringData) {
            autoToggleColors(letteringData.fill.pattern, letteringData.fill)
            autoFixOutline2(letteringData)
        }

        function autoFixOutline2(letteringData) {
            var outline1Prop = letteringData.outline_1
            var outline2Prop = letteringData.outline_2

            scope.$watch(
                'designData.' + outline1Prop.$path + '.value()',
                function (value) {
                    if (!value) {
                        return
                    }

                    outline2Prop.isDisabled('outline1', value === 'none')
                }
            )
        }

        /**
         * @type objects {object[]}
         * @type paths {string[]}
         * @type guardFn {Function}
         */
        function autoSync(objects, paths, guardFn) {
            objects = _.compact(objects)
            _.each(objects, function (object) {
                var otherProps = _.without(objects, object)
                _.each(paths, function (path) {
                    var source = _.get(object, path)
                    scope.$watch(
                        'designData.' + source.$path + '.value()',
                        function (value) {
                            _.each(otherProps, function (prop) {
                                var dest = _.get(prop, path)
                                if (
                                    dest.value() !== value &&
                                    (guardFn == null || guardFn())
                                ) {
                                    dest.value(value)
                                }
                            })
                        }
                    )
                })
            })
        }

        /**
         * @type props {LimitedProperty}
         */
        function autoUnset(props) {
            props = _.compact(props)
            _.each(props, function (prop) {
                var otherProps = _.without(props, prop)
                scope.$watch('designData.' + prop.$path + '.value()', function (
                    value
                ) {
                    if (!value || value === 'none') {
                        return
                    }

                    _.each(otherProps, function (otherProp) {
                        otherProp.value('none')
                    })
                })
            })
        }

        function autouseDecoPlacement(decos) {
            _.each(decos, function (deco) {
                scope.$watch(
                    'designData.' + deco.placement.$path + '.item()',
                    function (placement) {
                        var isDisabled = !placement || placement.code === 'none'

                        deco.size.isDisabled('placement', isDisabled)
                        deco.design &&
                            deco.design.isDisabled('placement', isDisabled)
                        deco.fill &&
                            deco.fill.color_1.isDisabled(
                                'placement',
                                isDisabled
                            )
                    }
                )
            })
        }
        function autoPreventTailing(deco) {
            if (!deco.tailing) {
                return
            }
            scope.$watch(
                'designData.' + deco.placement.$path + '.item()',
                function (placement) {
                    var isDisabled = !placement || placement.code === '0'
                    deco.tailing.enabled.isDisabled('placementIs0', isDisabled)
                }
            )
        }
    })

/* eslint-disable */
angular.module('mainApp').run(function (autoWatcher) {
    // force to activate autoWatcher
})
