'use strict'

angular.module('mainApp').factory('colorAreaButtonMap', function(_) {
    var garments = require('data').sheets.garments
    return _.chain(garments)
        .reject({ hasAssets: false })
        .indexBy('code')
        .mapValues(function(garment) {
            return _.chain(garment.colorAreas.titles)
                .pick(_.identity)
                .map(function(label, code) {
                    var index = parseInt(code.replace(/[^\d]+/, ''))
                    return {
                        code: code,
                        name:
                            label +
                            (garment.isReversible
                                ? ''
                                : ' (Color ' + index + ')'),
                        index: index,
                    }
                })
                .value()
        })
        .value()
})
